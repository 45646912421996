import { NgModule } from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {UserComponent} from './user.component';
import {UserRoutingModule} from './user-routing.module';

@NgModule({
  declarations: [UserComponent],
  imports: [CommonModule, FormsModule, UserRoutingModule, UserRoutingModule],
  providers: [],
  exports: []
})

export class UserModule {}
