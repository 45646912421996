import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about.component';

const routes: Routes = [
  { path: 'app', component: AboutComponent,
    children: [
      {path: 'privacy', component: AboutComponent},
      {path: 'about', component: AboutComponent},
      {path: 'copyright', component: AboutComponent}
    ] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AboutRoutingModule { }
