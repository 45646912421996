import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {WebService} from './util/webservice';
import {HttpClientModule} from '@angular/common/http';
import {HomeModule} from './page/home/home.module';
import {NaviStore} from './store/navigation.store';
import {ArticleModule} from './page/article/article.module';
import {UtilModule} from './page/common/common.module';
import {ChoiceModule} from "./page/choice/choice.module";
import {AboutModule} from "./page/about/about.module";
import {ArticleListModule} from "./page/article-list/article-list.module";
import {LoginModule} from "./page/login/login.module";
import {UserModule} from "./page/user/user.module";

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    HomeModule,
    ArticleModule,
    ChoiceModule,
    AboutModule,
    UtilModule,
    ArticleListModule,
    LoginModule,
    UserModule
  ],
  providers: [WebService, NaviStore],
  bootstrap: [AppComponent]
})
export class AppModule { }
