import { NgModule } from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ArticleListRoutingModule} from './article-list-routing.module';
import {ArticleListComponent} from "./article-list.component";

@NgModule({
  declarations: [ArticleListComponent],
  imports: [CommonModule, FormsModule, ArticleListRoutingModule],
  providers: [],
  exports: []
})

export class ArticleListModule {}
