import { Component, OnInit } from '@angular/core';
import {ResponseModel} from "../../model/response.model";
import {WebService} from "../../util/webservice";
import {NaviStore} from "../../store/navigation.store";
import {ActivatedRoute} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.css']
})
export class ArticleListComponent implements OnInit {

  search_list = {
    author_list: [],
    source_list: [],
    status_list: []
  };

  article_list = [];
  article_count = 0;

  search_condition = this.initCondition();

  initCondition() {
    return {
      title: '',
      content: '',
      author: '',
      source: '',
      status: '0',
      create_date: '',
      spider_date: '',
      spider_select: '2',
      start_spider_date: '',
      end_spider_date: '',
      word_count: '1',
      min_word_count: '',
      max_word_count: '',
      page_count: 200,
    };
  }

  constructor(private webservice: WebService,
              private naviStore: NaviStore,
              private route: ActivatedRoute
  ) {
    this.search();
  }

  ngOnInit() {
    this.get_search_filter_list();
  }

  get_search_filter_list() {
    this.webservice.getMethod({
      url: 'article/get_search_filter_list/',
      data: {
      }
    }).subscribe((res: ResponseModel) => {
      if (res.success) {
        console.log(res.data);
        this.search_list = res.data;
      }
    });
  }

  reset() {
    this.search_condition = this.initCondition();
  }

  search() {
    this.article_list = [];

    if (this.search_condition.spider_select == '') {
      this.search_condition.start_spider_date = '';
      this.search_condition.end_spider_date = '';
    } else if (this.search_condition.spider_select == '0'){
      this.search_condition.start_spider_date = this.getLastWeek().now + ' 00:00:00';
      this.search_condition.end_spider_date = '';
    } else if (this.search_condition.spider_select == '1') {

    } else if (this.search_condition.spider_select == '2') {
      this.search_condition.start_spider_date = this.getLastWeek().last + ' 00:00:00';
      this.search_condition.end_spider_date = '';
    } else if (this.search_condition.spider_select == '3') {
      this.search_condition.start_spider_date = this.getLastMonth().last + ' 00:00:00';
      this.search_condition.end_spider_date = '';
    }

    if (this.search_condition.word_count == '') {
      this.search_condition.min_word_count = '0';
      this.search_condition.max_word_count = '';
    } else if (this.search_condition.word_count == '0') {
      this.search_condition.min_word_count = '0';
      this.search_condition.max_word_count = '5000';
    } else if (this.search_condition.word_count == '1') {
      this.search_condition.min_word_count = '5000';
      this.search_condition.max_word_count = '';
    } else if (this.search_condition.word_count == '2') {
      this.search_condition.min_word_count = '10000';
      this.search_condition.max_word_count = '';
    }

    console.log(this.search_condition);

    this.webservice.getMethod({
      url: 'article/get_article_list/',
      data: this.search_condition
    }).subscribe((res: ResponseModel) => {
      if (res.success) {
        console.log(res.data);
        this.article_list = res.data.item;
        this.article_count = res.data.total;
      }
    });
  }

  edit(article_id, status, index) {
    this.webservice.postMethod({
      url: 'article/article_edit/',
      data: {
        article_id: article_id,
        status: status
      }
    }).subscribe((res: ResponseModel) => {
      if (res.success) {
        // this.search();
        this.article_list.splice(index, 1);
      }
    });
  }

  //获取近一周时间
  getLastWeek() {
    var now = new Date();
    var year = now.getFullYear();
    var month = now.getMonth() + 1;//0-11表示1-12月
    var day = now.getDate();
    var dateObj = {now: null, last: null};
    dateObj.now = year + '-' + month + '-' + day;
    if(day - 7 <= 0){   //如果在当月7日之前
      var lastMonthDay = new Date(year, (month - 1), 0).getDate();    //1周前所在月的总天数
      if(month - 1 <= 0){ //如果在当年的1月份
        dateObj.last = (year - 1) + '-' + 12 + '-' + (31 - (7 - day));
      }else{
        dateObj.last = year + '-' + (month - 1) + '-' + (lastMonthDay - (7 - day));
      }
    }else{
      dateObj.last = year + '-' + month + '-' + (day -7);
    }
    return dateObj;
  }

  //获取近一月的时间
  getLastMonth() {
    var now = new Date();
    var year = now.getFullYear();
    var month = now.getMonth() + 1;//0-11表示1-12月
    var day = now.getDate();
    var dateObj = {now: null, last: null};
    dateObj.now = year + '-' + month + '-' + day;
    var nowMonthDay = new Date(year, month, 0).getDate();    //当前月的总天数
    if(month - 1 <= 0){ //如果是1月，年数往前推一年<br>　　　　
      dateObj.last = (year - 1) + '-' + 12 + '-' + day;
    }else{
      var lastMonthDay = new Date(year, (month - 1), 0).getDate();
      if(lastMonthDay < day){    // 1个月前所在月的总天数小于现在的天日期
        if(day < nowMonthDay){        //当前天日期小于当前月总天数
          dateObj.last = year + '-' + (month - 1) + '-' + (lastMonthDay - (nowMonthDay - day));
        }else{
          dateObj.last = year + '-' + (month - 1) + '-' + lastMonthDay;
        }
      }else{
        dateObj.last = year + '-' + (month - 1) + '-' + day;
      }
    }
    return dateObj;
  }



}
