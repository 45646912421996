import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {WebService} from '../../util/webservice';
import {NaviStore} from '../../store/navigation.store';
import {ActivatedRoute} from '@angular/router';
import {ResponseModel} from '../../model/response.model';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'login',
  templateUrl: 'user.template.html',
  styleUrls: ['user.style.css'],
  encapsulation: ViewEncapsulation.None
})

export class UserComponent implements OnInit{
  user = {
    id: '',
    nickname: '',
    mobile: '',
  };

  constructor(private webservice: WebService,
              private naviStore: NaviStore,
              private route: ActivatedRoute,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.webservice.getMethod({
      url: 'user/get_user_info/',
      data: {
      }
    }).subscribe((res: ResponseModel) => {
      if (res.success) {
        console.log(res.data);
        this.user = res.data;
      }
    }, (error) => {
      if (error.status === 403) {
        this.naviStore.pushRoute("login");
      }
    });
  }
}
