/**
 * Created by zhangjinfei on 2016/8/9.
 */
import {HostBinding, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {RouteModel} from '../model/router.model';

@Injectable()
export class NaviStore {
  @HostBinding('@routeAnimation') routeAnimation = true;
  naviLists: RouteModel[];

  constructor(private router: Router) {
    this.naviLists = [];
  }

  // 清空路径列表
  clearNaviStore() {
    this.naviLists = [];
  }

  // 删除最新的一条路径
  pop() {
    this.naviLists.pop();
  }

  // 存储一条路径
  push(route: string, params: any = '') {
    this.naviLists.push(new RouteModel({router: route, params: params}));
  }

  // 删除最新的路径（如果存在），并导航至该路径
  popRoute() {
    if (this.naviLists.length > 0) {
      const lastRoute = this.naviLists.pop();

      if (lastRoute.params) {
        this.router.navigate([lastRoute.router, lastRoute.params]);
      } else {
        this.router.navigate([lastRoute.router]);
      }
    } else {
      this.router.navigate(['']);
    }
  }

  // 导航至该路径(不存储)
  pushRoute(route: string, params: any = '') {
    if (params) {
      this.router.navigate([route, params]);
    } else {
      this.router.navigate([route]);
    }
  }
}

