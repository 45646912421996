import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ResponseModel} from "../../../model/response.model";
import {WebService} from "../../../util/webservice";

@Component({
  selector: 'app-home-item',
  templateUrl: 'home-item.template.html',
  styleUrls: ['home-item.style.css']
})

export class HomeItemComponent implements OnChanges {
  @Input() article: any;

  constructor(private webservice: WebService) {
    this.article = {};
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['article'] && changes['article'].currentValue) {
      this.article = changes['article'].currentValue;
    }
  }
}
