import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {WebService} from '../../util/webservice';
import {NaviStore} from '../../store/navigation.store';
import {ActivatedRoute} from '@angular/router';
import {ResponseModel} from '../../model/response.model';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-choice',
  templateUrl: 'about.template.html',
  styleUrls: ['about.style.css'],
  encapsulation: ViewEncapsulation.None
})

export class AboutComponent implements OnInit{
  showType: string;
  privacyType: number;

  constructor(private webservice: WebService,
              private naviStore: NaviStore,
              private route: ActivatedRoute,
              private sanitizer: DomSanitizer) {
  }


  ngOnInit() {
    if(location.href.indexOf('privacy') > -1){
      this.showType = 'privacy';
    } else if(location.href.indexOf('copyright') > -1){
      this.showType = 'copyright';
    } else {
      this.showType = 'about';
    }
    this.privacyType = 1;
  }
}
