import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {WebService} from '../../util/webservice';
import {NaviStore} from '../../store/navigation.store';
import {ActivatedRoute} from '@angular/router';
import {ResponseModel} from '../../model/response.model';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-choice',
  templateUrl: 'choice.template.html',
  styleUrls: ['choice.style.css'],
  encapsulation: ViewEncapsulation.None
})

export class ChoiceComponent implements OnInit{
  search_list = {
    author_list: [],
    source_list: [],
    status_list: []
  };

  article_list = [];
  article_count = 0;

  search_condition = this.initCondition();

  initCondition() {
    return {
      title: '',
      content: '',
      author: '',
      source: '',
      status: '1',
      create_date: '',
      spider_date: '',
      spider_select: '',
      start_spider_date: '',
      end_spider_date: '',
      word_count: '',
      min_word_count: '',
      max_word_count: '',
      page_count: 200,
    };
  }

  constructor(private webservice: WebService,
              private naviStore: NaviStore,
              private route: ActivatedRoute
  ) {
    this.search();
  }

  ngOnInit() {
    this.get_search_filter_list();
  }

  get_search_filter_list() {
    this.webservice.getMethod({
      url: 'article/get_search_filter_list/',
      data: {
      }
    }).subscribe((res: ResponseModel) => {
      if (res.success) {
        console.log(res.data);
        this.search_list = res.data;
      }
    });
  }

  reset() {
    this.search_condition = this.initCondition();
  }

  search() {
    this.article_list = [];

    console.log(this.search_condition);

    this.webservice.getMethod({
      url: 'article/get_article_list/',
      data: this.search_condition
    }).subscribe((res: ResponseModel) => {
      if (res.success) {
        console.log(res.data);
        this.article_list = res.data.item;
        this.article_count = res.data.total;
      }
    });
  }
}
