import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {WebService} from '../../util/webservice';
import {NaviStore} from '../../store/navigation.store';
import {ActivatedRoute} from '@angular/router';
import {ResponseModel} from '../../model/response.model';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'login',
  templateUrl: 'login.template.html',
  styleUrls: ['login.style.css'],
  encapsulation: ViewEncapsulation.None
})

export class LoginComponent implements OnInit{
  mobile: string;
  password: string;

  constructor(private webservice: WebService,
              private naviStore: NaviStore,
              private route: ActivatedRoute,
              private sanitizer: DomSanitizer) {
  }

  login() {
    this.webservice.postMethod({
      url: 'user/login_with_password/',
      data: {
        mobile: this.mobile,
        password: this.password
      }
    }).subscribe((res: ResponseModel) => {
      if (res.success) {
        localStorage.setItem('TOKEN', res.data.token);
        this.webservice.refreshToken(res.data.token);
        this.naviStore.popRoute();
      } else {
        window.alert(res.msg);
      }
    });
  }

  ngOnInit(): void {
  }
}
