import { Component } from '@angular/core';
import {WebService} from "../../../util/webservice";
import {NaviStore} from "../../../store/navigation.store";
import {ActivatedRoute} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-header',
  templateUrl: 'header.template.html',
  styleUrls: ['header.style.css']
})

export class HeaderComponent {
  activeIndex = 1;

  constructor(private webservice: WebService,
              private naviStore: NaviStore,
              private route: ActivatedRoute,
              private sanitizer: DomSanitizer) {

  }

  backToTop(event) {

  }

  changePage(event) {
    if(event.target.dataset.index == 1 && this.activeIndex != 1) {
      this.activeIndex = 1;
      this.naviStore.pushRoute('');
    } else if(event.target.dataset.index == 2 && this.activeIndex != 2) {
      this.activeIndex = 2;
      this.naviStore.pushRoute('article-list');
    } else if(event.target.dataset.index == 3 && this.activeIndex != 3) {
      this.activeIndex = 3;
      this.naviStore.pushRoute('choice');
    } else if(event.target.dataset.index == 4 && this.activeIndex != 4) {
      this.activeIndex = 4;
      this.naviStore.pushRoute('user');
    }
  }
}
