import {AfterViewInit, Component, OnDestroy, OnInit, HostListener} from '@angular/core';
import {WebService} from '../../util/webservice';
import {ResponseModel} from '../../model/response.model';
import {Route} from "@angular/router";

@Component({
  templateUrl: 'home.template.html',
  styleUrls: ['home.style.css']
})

export class HomeComponent {
  article_list: any;
  status: number;
  page_index: number;
  page_count: number;
  load_done: boolean;
  source_list: any;
  choose_source: number;
  search_title: string;

  constructor(private webservice: WebService ) {
    this.article_list = [];
    this.source_list = [];
    this.status = 0;
    this.page_index = 1;
    this.page_count = 11;
    this.choose_source = 0;
    this.search_title = '';
  }


  ngOnInit() {
    this.get_article();
    this.get_source_list();
  }

  get_source_list(){
    this.webservice.getMethod({
      url: 'general/get_source_list/',
      data: {
        source_id: this.choose_source
      }
    }).subscribe((res: ResponseModel) => {
      if (res.success){
        this.source_list = res.data;
      }
    });
  }

  change_source(source){
    this.article_list = [];
    this.page_index = 1;
    this.choose_source = source;
    this.load_done = false;
    this.get_article();
  }

  change_title(title){
    this.article_list = [];
    this.page_index = 1;
    this.load_done = false;
    this.search_title = title;
    this.get_article();
  }

  get_article() {
    this.webservice.getMethod({
      url: 'article/get_article_list/',
      data: {
        status: this.status,
        page_index: this.page_index,
        page_count: this.page_count,
        source: this.choose_source,
        title: this.search_title
      }
    }).subscribe((res: ResponseModel) => {
      if (res.success) {
        res.data.item.forEach(item => {
          this.article_list.push(item);
        });
        if(res.data.item.length < this.page_count){
          this.load_done = true;
        }
      }
    });
  }

  get_more_article(){
    this.page_index++;
    this.get_article();
  }

  change_status(status){
    this.status = status;
    this.page_index = 1;
    this.article_list = [];
    this.get_article();
  }

  censor(status, article) {
    this.webservice.getMethod({
      url: 'article/change_article_status/',
      data: {
        article_id: article.id,
        status: status
      }
    }).subscribe((res: ResponseModel) => {
      if (res.success) {
        article.status = status;
      }
    });
  }

  edit(article) {
    let name=prompt(article.title,article.title);
    if (name!=null && name!=""){
      this.webservice.getMethod({
        url: 'article/article_edit/',
        data: {
          article_id: article.id,
          title: name
        }
      }).subscribe((res: ResponseModel) => {
        if (res.success) {
          article.title = name;
        }
      });
    }
  }
}
