import { NgModule } from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ArticleComponent} from './article.component';
import {ArticleRoutingModule} from './article-routing.module';

@NgModule({
  declarations: [ArticleComponent],
  imports: [CommonModule, FormsModule, ArticleRoutingModule],
  providers: [],
  exports: []
})

export class ArticleModule {}
