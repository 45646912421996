import { NgModule } from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ChoiceComponent} from './choice.component';
import {ChoiceRoutingModule} from './choice-routing.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, FormsModule, ChoiceRoutingModule],
  providers: [],
  exports: []
})

export class ChoiceModule {}
