import { NgModule } from '@angular/core';
import {HomeRoutingModule} from './home-routing.module';
import {HomeComponent} from './home.component';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ChoiceComponent} from "../choice/choice.component";
import {HomeItemComponent} from "../article/home-item/home-item.component";
import {UtilModule} from "../common/common.module";

@NgModule({
  declarations: [HomeComponent, ChoiceComponent, HomeItemComponent],
  imports: [CommonModule, FormsModule, HomeRoutingModule, UtilModule],
  providers: [],
  exports: [HomeComponent]
})

export class HomeModule {}
