import { environment } from '../../environments/environment';

export class ApiList {
  apiUrl: string;

  constructor() {
    // console.log(navigator.platform);
    if (window.location.href.indexOf("localhost") !== -1) {
      this.apiUrl = 'http://localhost:8000/api/v1/';
      // this.apiUrl = 'api/v1/';
    } else {
      this.apiUrl = 'api/v1/';
    }
  }
}

