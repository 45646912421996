import {AfterViewInit, Component} from '@angular/core';
import {WebService} from './util/webservice';
import {NaviStore} from "./store/navigation.store";
import {ActivatedRoute} from "@angular/router";
import {ResponseModel} from "./model/response.model";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {

  constructor(private webservice: WebService,
              private naviStore: NaviStore,
              private route: ActivatedRoute
  ) {
  }

  ngAfterViewInit() {
    this.webservice.getMethod({
      url: 'user/get_user_info/',
      data: {
      }
    }).subscribe((res: ResponseModel) => {
      if (res.success) {
        console.log(res.data);
      }
    }, (error) => {
      if (error.status === 403) {
        this.naviStore.pushRoute("login");
      }
    });
  }
}
