import { Component } from '@angular/core';

@Component({
  selector: 'app-scroll-top',
  templateUrl: 'scroll-top.template.html',
  styleUrls: ['scroll-top.style.css']
})

export class ScrollTopComponent {

  backToTop(event) {

  }
}
