import { NgModule } from '@angular/core';
import {ScrollTopComponent} from './scroll-top/scroll-top.component';
import {HeaderComponent} from "./header/header.component";

@NgModule({
  declarations: [ScrollTopComponent, HeaderComponent],
  imports: [],
  providers: [],
  exports: [
    HeaderComponent
  ]
})

export class UtilModule {

}
