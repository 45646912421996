import {Injectable} from '@angular/core';
import {ApiList} from './apiList';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NaviStore} from "../store/navigation.store";

@Injectable()
export class WebService {
  private apiUrl: string;
  private token: string;
  private tokenPrefix = '';

  constructor(private http: HttpClient, private naviStore: NaviStore) {
    this.apiUrl = new ApiList().apiUrl;
    this.token = localStorage.getItem('TOKEN');
  }

  getMethod(options: any) {
    let url = options.fullUrl || this.apiUrl + options.url;
    const headers = new HttpHeaders({
      'Authorization': this.tokenPrefix + this.token
    });

    let searchString = '';
    const option = options['data'];
    for (const key in option) {
      searchString += key + '=' + option[key] + '&';
    }
    if (searchString) {
      url = url + '?' + searchString;
    }
    return this.http.get(url, {headers: headers});
  }

  postMethod(options: any) {
    const url = options.fullUrl || this.apiUrl + options.url;

    // if upload file, 'Content-type': 'multipart/form-data', default: 'Content-Type': 'application/x-www-form-urlencoded'
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.tokenPrefix + this.token,
    });

    return this.http.post(url, options.data, {headers: headers});
    // try {
    //
    // } catch (e) {
    //   console.log(e.status);
    // }
  }


  putMethod(options: any) {
    const url = options.fullUrl || this.apiUrl + options.url;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.tokenPrefix + this.token
    });

    return this.http.put(url, options.data, {headers: headers});
  }

  refreshToken(token: string) {
    this.token = token;
  }
}
