import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {WebService} from '../../util/webservice';
import {NaviStore} from '../../store/navigation.store';
import {ActivatedRoute} from '@angular/router';
import {ResponseModel} from '../../model/response.model';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'app-favorite',
  templateUrl: 'article.template.html',
  styleUrls: ['article.style.css'],
  encapsulation: ViewEncapsulation.None
})

export class ArticleComponent implements OnInit{
  article: any;
  article_id: string;
  public styles: SafeHtml;

  constructor(private webservice: WebService,
              private naviStore: NaviStore,
              private route: ActivatedRoute,
              private sanitizer: DomSanitizer) {
    this.article = {};
  }

  ngOnInit() {
    this.article_id = this.route.snapshot.paramMap.get('id');
    this.get_article();

  }

  get_article() {
    this.webservice.getMethod({
      url: 'article/get_article_info/',
      data: {
        article_id: this.article_id
      }
    }).subscribe((res: ResponseModel) => {
      if (res.success) {
        this.article = res.data;
        this.article.content = this.sanitizer.bypassSecurityTrustHtml(this.article.content);
        const head = document.getElementsByTagName('head')[0];
        const style = document.createElement('style');
        style.appendChild(document.createTextNode(res.data.source_style));
        head.appendChild(style);
      }
    });
  }
}
